import { createNewCart } from '../ShopifyMutations/CreateNewCart'
import { addCartLines } from '../ShopifyMutations/AddCartLines'
import { removeCartLineItems } from '../ShopifyMutations/RemoveCartLineItems'
import { updateCartLineItems } from '../ShopifyMutations/UpdateCartLineItems'
import { applyDiscountToCart } from '../ShopifyMutations/ApplyDiscountToCart'
import { removeDiscountFromCart } from '../ShopifyMutations/RemoveDiscountFromCart'

export const useCartActions = (store, updateStore) => {
  const addToCart = async (lineItems) => {
    let error = null
    lineItems.forEach(({ variantId, quantity }) => {
      if (!variantId) error = 'Please select a product.'
      if (!quantity || quantity <= 0) error = 'Please input a quantity greater than 0'
    })

    if (error) return { error }

    updateStore((prevState) => ({ ...prevState, adding: true }))

    const { cart } = store
    const {
      banner: { promoCode },
    } = store

    let { id: cartId } = cart

    if (!cartId) {
      try {
        const newCart = await createNewCart()
        if (newCart && newCart.id) {
          cartId = newCart.id
          updateStore((prevState) => ({ ...prevState, cart: newCart }))
        } else {
          console.error('Failed to create a new cart.')
          updateStore((prevState) => ({ ...prevState, adding: false }))
          return { error: 'Failed to create a new cart.' }
        }
      } catch (e) {
        console.error('Error creating new cart:', e)
        updateStore((prevState) => ({ ...prevState, adding: false }))
        return { error: 'Error creating new cart.' }
      }
    }
    const lines = lineItems.map(({ variantId, quantity }) => ({
      merchandiseId: variantId,
      quantity: parseInt(quantity, 10),
    }))

    try {
      const updatedCart = await addCartLines(cartId, lines)
      if (updatedCart) {
        updateStore((prevState) => ({ ...prevState, cart: updatedCart, adding: false }))
        if (promoCode) {
          try {
            const discountApplied = await applyDiscountToCart(cartId, promoCode)
            if (!discountApplied) {
              console.error('Failed to apply discount to cart.')
            }
          } catch (error) {
            console.error('Error applying discount to cart:', error)
          }
        }
      } else {
        console.error('Failed to add item to cart.')
        updateStore((prevState) => ({ ...prevState, adding: false }))
      }
    } catch (error) {
      console.error('Error adding lines to cart:', error)
      updateStore((prevState) => ({ ...prevState, adding: false }))
    }
  }

  const removeLineItem = async (lineId) => {
    const { cart } = store
    const cartId = cart?.id

    if (!cartId) {
      console.error('No cart ID available to remove line item.')
      return
    }

    try {
      const updatedCart = await removeCartLineItems(cartId, [lineId])

      if (updatedCart) {
        updateStore((prevState) => ({ ...prevState, cart: updatedCart }))
      } else {
        console.error('Failed to remove item from cart.')
      }
    } catch (error) {
      console.error('Error removing item from cart:', error)
    }
  }

  const updateLineItem = async (lineId, quantity) => {
    const { cart } = store
    const cartId = cart?.id

    if (!cartId) {
      console.error('No cart ID available to update line item.')
      return
    }

    const lines = [
      {
        id: lineId,
        quantity: parseInt(quantity, 10),
      },
    ]

    try {
      const updatedCart = await updateCartLineItems(cartId, lines)
      if (updatedCart) {
        updateStore((prevState) => ({ ...prevState, cart: updatedCart }))
      } else {
        console.error('Failed to update item in cart.')
      }
    } catch (error) {
      console.error('Error updating item in cart:', error)
    }
  }

  const applyDiscount = async (code) => {
    const { cart } = store
    const cartId = cart?.id

    if (!cartId) {
      console.error('No cart ID available to apply discount.')
      return
    }

    try {
      const updatedCart = await applyDiscountToCart(cartId, code)
      if (updatedCart) {
        updateStore((prevState) => ({ ...prevState, cart: updatedCart }))
      } else {
        console.error('Failed to apply discount.')
      }
    } catch (error) {
      console.error('Error applying discount to cart:', error)
    }
  }

  const removeDiscount = async () => {
    const { cart } = store
    const cartId = cart?.id

    if (!cartId) {
      console.error('No cart ID available to remove discount.')
      return
    }

    try {
      const updatedCart = await removeDiscountFromCart(cartId)
      if (updatedCart) {
        updateStore((prevState) => ({ ...prevState, cart: updatedCart }))
      } else {
        console.error('Failed to remove discount.')
      }
    } catch (error) {
      console.error('Error removing discount from cart:', error)
    }
  }

  const isSubscriptionItem = (item) => /plus/i.test(item.node.merchandise.product.title)

  // Recharge checkout functions
  const isUserBuyingPlus = (cart) => {
    if (!cart.lines || !cart.lines.edges || cart.lines.edges.length === 0) {
      return false
    }
    return cart.lines.edges.some((item) => isSubscriptionItem(item))
  }

  return {
    addToCart,
    removeLineItem,
    updateLineItem,
    applyDiscount,
    removeDiscount,
    isUserBuyingPlus,
  }
}
